import { NavLink } from "react-router-dom";

export const ProjectCard = ({ project, hideNavMenu }) => {
  //
  return (
    <NavLink exact to={`/${project.slug}`} onClick={hideNavMenu}>
      <img
        src={
          process.env.PUBLIC_URL +
          `/images/thumbnails/${project.slug}-thumb.png`
        }
        alt={project.title}
      />
      <div className="mt-2">
        <p>{project.title}</p>
        <p className="text-xs text-gray-400 mt-1">
          {project.categories.join(" / ")}
        </p>
      </div>
    </NavLink>
  );
};
