import { CalendarIcon, LinkIcon, MarkGithubIcon } from "@primer/octicons-react";

export const Project = ({ projectData }) => {
  //
  return (
    <div id="content-page" className="space-y-8">
      <div className="space-y-8">
        <h1 className="text-4xl font-extralight">{projectData.title}</h1>
        <p>
          <CalendarIcon verticalAlign={"bottom"} className="mb-1 mr-2" />
          {projectData.date}
        </p>
        <div className="grid gap-8 xl:grid-cols-2">
          <div className="space-y-4">
            <projectData.intro />
          </div>
          {projectData.externalLinks.length > 0 && (
            <ul className="text-gray-400 space-y-2">
              {projectData.externalLinks.map((link, index) => (
                <li key={index} className="space-x-2 align-middle">
                  <a href={link}>
                    {link.includes("github.com") ? (
                      <MarkGithubIcon
                        verticalAlign={"bottom"}
                        className="mb-1 mr-2"
                      />
                    ) : (
                      <LinkIcon
                        verticalAlign={"bottom"}
                        className="mb-1 mr-2"
                      />
                    )}
                    {link.replace(/(^\w+:|^)\/\//, "")}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <projectData.main />
    </div>
  );
};
