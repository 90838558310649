import { NavLink } from "react-router-dom";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MoonIcon,
  SunIcon,
} from "@primer/octicons-react";
import { Nav } from "../components";

const resumeEnPath =
  process.env.PUBLIC_URL + `/files/ayrton-mugnaini-cv-2021-en.pdf`;

export const Header = ({
  navMenuVisibility,
  toggleNavMenu,
  hideNavMenu,
  darkTheme,
  toggleDarkTheme,
}) => {
  //
  return (
    <>
      <header className="container mx-auto flex justify-between">
        <NavLink to="/" className="font-bold" onClick={hideNavMenu}>
          Ayrton Mugnaini
        </NavLink>
        <div className="flex space-x-6">
          <ul className="space-x-6 hidden sm:flex">
            <li>
              <NavLink
                to="/about"
                className="focus:outline-none"
                onClick={hideNavMenu}
              >
                About
              </NavLink>
            </li>
            <li>
              <a href={resumeEnPath}>Resume</a>
            </li>
          </ul>
          <button
            className="focus:outline-none lg:hidden"
            onClick={toggleNavMenu}
          >
            {navMenuVisibility ? (
              <>
                <span className="border-b py-1 border-current">
                  Menu <ChevronUpIcon className="mb-px" />
                </span>
              </>
            ) : (
              <>
                Menu <ChevronDownIcon className="mb-px" />
              </>
            )}
          </button>
          <button className="focus:outline-none" onClick={toggleDarkTheme}>
            {darkTheme ? (
              <>
                <SunIcon className="mb-px" />
              </>
            ) : (
              <>
                <MoonIcon className="mb-px" />
              </>
            )}
          </button>
        </div>
      </header>
      <div className={`${navMenuVisibility ? "block lg:hidden" : "hidden"}`}>
        <Nav hideNavMenu={hideNavMenu} />
      </div>
    </>
  );
};
