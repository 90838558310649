import { NavLink } from "react-router-dom";
import { ExternalLink } from "./";
import { projectsData } from "./../data/projectsData";

const resumeEnPath =
  process.env.PUBLIC_URL + `/files/ayrton-mugnaini-cv-2021-en.pdf`;

export const Nav = ({ hideNavMenu }) => {
  //
  return (
    <nav className="grid gap-6">
      <ul className="md:hidden">
        <li>
          <NavLink
            to="/about"
            className="focus:outline-none"
            onClick={hideNavMenu}
          >
            About
          </NavLink>
        </li>
        <li>
          <a href={resumeEnPath}>Resume</a>
        </li>
      </ul>
      <ul>
        {projectsData.map((project, index) => {
          return (
            <li key={index}>
              <NavLink
                exact
                to={`/${project.slug}`}
                onClick={hideNavMenu}
                activeClassName={
                  "font-bold md:list-item md:list-outside md:list-disc"
                }
              >
                {project.title}
              </NavLink>
            </li>
          );
        })}
      </ul>
      <ul className="text-gray-400">
        <li>
          <ExternalLink url={"https://www.linkedin.com/in/ayrtonmugnaini"}>
            LinkedIn
          </ExternalLink>
        </li>
        <li>
          <ExternalLink url={"https://github.com/yrto"}>GitHub</ExternalLink>
        </li>
        <li>
          <ExternalLink url={"https://www.instagram.com/ayrtonmugnaini/"}>Instagram</ExternalLink>
        </li>
      </ul>
    </nav>
  );
};
