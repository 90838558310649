export const projectsData = [
  {
    title: "Employer Branding",
    slug: "employer-branding",
    date: 2020,
    categories: ["Concept", "Planning", "Storytelling"],
    externalLinks: ["https://deloitte.com/vagas"],
    intro: () => (
      <>
        <p>
          Original concept and planning for Deloitte Brazil's 2020 employer
          branding campaign, focusing on diversity and inclusion. I was also
          responsible for the new recruitment page's UI design. Photo and video
          production made by the awesome team at{" "}
          <a href="https://ledome.com.br/">Ledome</a>.
        </p>
        <p>
          Acclaimed as the best "Talent Acquisition Campaign" by Deloitte's
          global branding team at the Green Globes 2020.
        </p>
      </>
    ),
    main: () => (
      <>
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/employer-branding-01.jpg`
          }
          alt="employer-branding"
        />
        <div className="aspect-w-16 aspect-h-9">
          <iframe
            title="Employer Branding - Making Of"
            src="https://www.youtube.com/embed/R_SN6s4GpSQ"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/employer-branding-02.jpg`
          }
          alt="employer-branding"
        />
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/employer-branding-03.jpg`
          }
          alt="employer-branding"
        />
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/employer-branding-05.jpg`
          }
          alt="employer-branding"
        />
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/employer-branding-04.jpg`
          }
          alt="employer-branding"
        />
      </>
    ),
  },
  {
    title: "Who's That Pokémon?",
    slug: "whos-that-pokemon",
    date: 2021,
    categories: ["React", "Tailwind"],
    externalLinks: [
      "https://whos-that-pokemon-react.surge.sh",
      "https://github.com/yrto/whos-that-pokemon",
    ],
    intro: () => (
      <p>
        A React JS recreation of the original interlude "game" from the Pokémon
        Animated Series. This project is using{" "}
        <a href="https://pokeapi.co/">PokéAPI</a> to fetch data and{" "}
        <a href="https://pokedevs.gitbook.io/pokedex/">PokéDex API</a> to fetch
        images.
      </p>
    ),
    main: () => (
      <>
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/whos-that-pokemon-01.jpg`
          }
          alt="whos-that-pokemon"
        />
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/whos-that-pokemon-02.jpg`
          }
          alt="whos-that-pokemon"
        />
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/whos-that-pokemon-03.jpg`
          }
          alt="whos-that-pokemon"
        />
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/whos-that-pokemon-04.jpg`
          }
          alt="whos-that-pokemon"
        />
      </>
    ),
  },
  {
    title: "Glamour Web Stories",
    slug: "glamour-web-stories",
    date: 2020,
    categories: ["Motion Design"],
    externalLinks: [],
    intro: () => (
      <p>
        Series of Web Stories made for{" "}
        <a href="https://glo.bo/3wOrIDG">Glamour Magazine</a>.
      </p>
    ),
    main: () => (
      <>
        <div className="aspect-w-16 aspect-h-9">
          <iframe
            title="Glamour Web Stories"
            src="https://www.youtube.com/embed/w_no0mG4rFU"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <img
          src={
            process.env.PUBLIC_URL +
            `/images/projects/glamour-web-stories-01.jpg`
          }
          alt="glamour-web-stories"
        />
        <img
          src={
            process.env.PUBLIC_URL +
            `/images/projects/glamour-web-stories-02.jpg`
          }
          alt="glamour-web-stories"
        />
        <img
          src={
            process.env.PUBLIC_URL +
            `/images/projects/glamour-web-stories-03.jpg`
          }
          alt="glamour-web-stories"
        />
        <img
          src={
            process.env.PUBLIC_URL +
            `/images/projects/glamour-web-stories-04.jpg`
          }
          alt="glamour-web-stories"
        />
        <img
          src={
            process.env.PUBLIC_URL +
            `/images/projects/glamour-web-stories-05.jpg`
          }
          alt="glamour-web-stories"
        />
        <img
          src={
            process.env.PUBLIC_URL +
            `/images/projects/glamour-web-stories-06.jpg`
          }
          alt="glamour-web-stories"
        />
        <img
          src={
            process.env.PUBLIC_URL +
            `/images/projects/glamour-web-stories-07.jpg`
          }
          alt="glamour-web-stories"
        />
        <img
          src={
            process.env.PUBLIC_URL +
            `/images/projects/glamour-web-stories-08.jpg`
          }
          alt="glamour-web-stories"
        />
        <img
          src={
            process.env.PUBLIC_URL +
            `/images/projects/glamour-web-stories-09.jpg`
          }
          alt="glamour-web-stories"
        />
        <img
          src={
            process.env.PUBLIC_URL +
            `/images/projects/glamour-web-stories-10.jpg`
          }
          alt="glamour-web-stories"
        />
      </>
    ),
  },
  {
    title: "OneDollar",
    slug: "onedollar",
    date: 2018,
    categories: ["Game Design", "Art Direction", "Animation"],
    externalLinks: ["https://onedollar.itch.io/onedollar"],
    intro: () => (
      <>
        <p>
          Art direction, animations and 2D assets for the game OneDollar, made
          during the 48 hour{" "}
          <a href="https://gamejamplus.com/">Game Jam Plus</a> in 2018. It
          intented helping Unicef raise donations to keep changing the lives of
          thousands of children and teenagers.
        </p>
        <p>
          Acclaimed as best game in the "Social Impact" category by an Unicef
          representative.
        </p>
      </>
    ),
    main: () => (
      <>
        <img
          src={process.env.PUBLIC_URL + `/images/projects/onedollar-01.jpg`}
          alt="onedollar"
        />
        <img
          src={process.env.PUBLIC_URL + `/images/projects/onedollar-03.jpg`}
          alt="onedollar"
        />
        <img
          src={process.env.PUBLIC_URL + `/images/projects/onedollar-04.jpg`}
          alt="onedollar"
        />
        <img
          src={process.env.PUBLIC_URL + `/images/projects/onedollar-05.jpg`}
          alt="onedollar"
        />
        <img
          src={process.env.PUBLIC_URL + `/images/projects/onedollar-06.jpg`}
          alt="onedollar"
        />
        <img
          src={process.env.PUBLIC_URL + `/images/projects/onedollar-07.jpg`}
          alt="onedollar"
        />
        <img
          src={process.env.PUBLIC_URL + `/images/projects/onedollar-08.jpg`}
          alt="onedollar"
        />
        <img
          src={process.env.PUBLIC_URL + `/images/projects/onedollar-02.jpg`}
          alt="onedollar"
        />
      </>
    ),
  },
  // {
  //   title: "Digital Business Cards",
  //   slug: "digital-business-cards",
  //   date: 2020,
  //   categories: ["Interactive Design"],
  //   externalLinks: [],
  //   intro: () => (
  //     <p>
  //       Planning and prototyping for Deloitte Brazil's transition from physical
  //       to digital business cards. Project developed by the relentless team at{" "}
  //       <a href="https://www.agenciagh.com.br/">GH Branding</a>.
  //     </p>
  //   ),
  //   main: () => (
  //     <>
  //       <img
  //         src={
  //           process.env.PUBLIC_URL +
  //           `/images/projects/digital-business-cards-01.jpg`
  //         }
  //         alt="digital-business-cards"
  //       />
  //       <img
  //         src={
  //           process.env.PUBLIC_URL +
  //           `/images/projects/digital-business-cards-03.jpg`
  //         }
  //         alt="digital-business-cards"
  //       />
  //       <img
  //         src={
  //           process.env.PUBLIC_URL +
  //           `/images/projects/digital-business-cards-02.jpg`
  //         }
  //         alt="digital-business-cards"
  //       />
  //       <img
  //         src={
  //           process.env.PUBLIC_URL +
  //           `/images/projects/digital-business-cards-04.jpg`
  //         }
  //         alt="digital-business-cards"
  //       />
  //     </>
  //   ),
  // },
  {
    title: "Retail Infographics",
    slug: "retail-infographics",
    date: "2017 — 2018",
    categories: ["Information Design"],
    externalLinks: [],
    intro: () => (
      <p>Infographics for Deloitte's annual Christmas and retail surveys.</p>
    ),
    main: () => (
      <>
        <img
          src={
            process.env.PUBLIC_URL +
            `/images/projects/retail-infographics-01.png`
          }
          alt="retail-infographics"
        />
        <img
          src={
            process.env.PUBLIC_URL +
            `/images/projects/retail-infographics-02.png`
          }
          alt="retail-infographics"
        />
      </>
    ),
  },
  {
    title: "Figure Drawing",
    slug: "figure-drawing",
    date: "2018",
    categories: ["Art"],
    externalLinks: [],
    intro: () => (
      <>
        <p>
          Favorite pieces from a 3-month figure drawing course at SESC Pompéia
          with the insanelly talented{" "}
          <a href="http://www.omenelick2ato.com/artes-plasticas/a-vontade-foi-demais-entrevista-claudinei-roberto">
            Claudinei Roberto
          </a>
          .
        </p>
      </>
    ),
    main: () => (
      <>
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/figure-drawing-01.jpg`
          }
          alt="figure-drawing"
        />
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/figure-drawing-02.jpg`
          }
          alt="figure-drawing"
        />
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/figure-drawing-03.jpg`
          }
          alt="figure-drawing"
        />
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/figure-drawing-04.jpg`
          }
          alt="figure-drawing"
        />
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/figure-drawing-05.jpg`
          }
          alt="figure-drawing"
        />
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/figure-drawing-06.jpg`
          }
          alt="figure-drawing"
        />
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/figure-drawing-07.jpg`
          }
          alt="figure-drawing"
        />
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/figure-drawing-08.jpg`
          }
          alt="figure-drawing"
        />
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/figure-drawing-09.jpg`
          }
          alt="figure-drawing"
        />
        <img
          src={
            process.env.PUBLIC_URL + `/images/projects/figure-drawing-10.jpg`
          }
          alt="figure-drawing"
        />
      </>
    ),
  },
  {
    title: "Logos",
    slug: "logos",
    date: "2017 — 2021",
    categories: ["Branding"],
    externalLinks: [],
    intro: () => (
      <p>
        Collection of logos designed for personal projects and freelance work.
      </p>
    ),
    main: () => (
      <>
        <img
          src={process.env.PUBLIC_URL + `/images/projects/logos-09.jpg`}
          alt="logos"
        />
        <img
          src={process.env.PUBLIC_URL + `/images/projects/logos-01.jpg`}
          alt="logos"
        />
        <img
          src={process.env.PUBLIC_URL + `/images/projects/logos-02.jpg`}
          alt="logos"
        />
        <img
          src={process.env.PUBLIC_URL + `/images/projects/logos-03.jpg`}
          alt="logos"
        />
        <img
          src={process.env.PUBLIC_URL + `/images/projects/logos-04.jpg`}
          alt="logos"
        />
        <img
          src={process.env.PUBLIC_URL + `/images/projects/logos-05.jpg`}
          alt="logos"
        />
        <img
          src={process.env.PUBLIC_URL + `/images/projects/logos-06.jpg`}
          alt="logos"
        />
        <img
          src={process.env.PUBLIC_URL + `/images/projects/logos-07.jpg`}
          alt="logos"
        />
        <img
          src={process.env.PUBLIC_URL + `/images/projects/logos-08.jpg`}
          alt="logos"
        />
      </>
    ),
  },
  // {
  //   title: "Live Model Drawings",
  //   slug: "live-model-drawings",
  //   categories: "Drawing",
  // },
  // {
  //   title: "Forty Five Degrees",
  //   slug: "forty-five-degrees",
  //   categories: "Typography",
  // },
];
