import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useState } from "react";
import { Header, Nav, Footer, ScrollToTop } from "./components";
import { Home, Project, About, NoMatch } from "./pages";
import { projectsData } from "./data/projectsData";

const App = () => {
  //
  const [navMenuVisibility, setNavMenuVisibility] = useState(false);
  const toggleNavMenu = () => setNavMenuVisibility(!navMenuVisibility);
  const hideNavMenu = () => setNavMenuVisibility(false);

  const isDarkThemeActive = localStorage.getItem("dark-theme") === "true";
  const [darkTheme, setDarkTheme] = useState(isDarkThemeActive);
  const toggleDarkTheme = () => {
    setDarkTheme(!darkTheme);
    localStorage.setItem("dark-theme", !darkTheme);
  };

  return (
    <div
      className={
        darkTheme
          ? "bg-gray-800 text-gray-100 min-h-screen"
          : "bg-gray-100 text-gray-900 min-h-screen"
      }
    >
      <Router>
        <ScrollToTop />
        <div className="container mx-auto p-4 md:p-8 space-y-8 xl:p-16">
          <Header
            navMenuVisibility={navMenuVisibility}
            toggleNavMenu={toggleNavMenu}
            hideNavMenu={hideNavMenu}
            darkTheme={darkTheme}
            toggleDarkTheme={toggleDarkTheme}
          />
          <div className="grid lg:grid-cols-4 gap-8">
            <div className="col-span-full lg:block lg:col-auto hidden">
              <Nav hideNavMenu={hideNavMenu} />
            </div>
            <div className="space-y-8 lg:col-span-3">
              <Switch>
                <Route exact path="/">
                  <Home hideNavMenu={hideNavMenu} />
                </Route>
                {projectsData.map((project, index) => (
                  <Route
                    key={index}
                    path={`/${project.slug}`}
                    exact={true}
                    children={<Project projectData={project} />}
                  />
                ))}
                <Route exact path="/about">
                  <About />
                </Route>
                <Route path="*">
                  <NoMatch />
                </Route>
              </Switch>
              <Footer />
            </div>
          </div>
        </div>
      </Router>
    </div>
  );
};

export default App;
