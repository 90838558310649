export function About() {
  //
  return (
    <div id="content-page" className="grid gap-8 md:grid-cols-2">
      <div className="space-y-8">
        <div>
          <h1 className="text-4xl font-extralight">Hi, I'm Ayrton.</h1>
          <p className="text-gray-400 mt-2">Graphic Designer & Developer</p>
        </div>
        <div className="space-y-4">
          <p>
            I've been working with visual communications since 2011 in various
            design fields: branding, digital, print, motion, etc. By experience,
            I've learned that collaboration plays a huge part in my creative
            process and sharing knowledge is vital in finding solutions for
            intricate problems.
          </p>
          <p>
            I'm always trying to learn about new technologies and tools, so I’m
            currently studying full-stack development (mostly with Node and
            React) in order to be able to create solutions for the web.
          </p>
          <p>
            I believe that it's possible to achieve results in an uncomplicated,
            inclusive and human way.
          </p>
          <p>
            <span className="mr-2">👋</span>
            Say hi at:
          </p>
          <p>
            <a href="mailto:amugnaini@outlook.com">amugnaini@outlook.com</a>
          </p>
        </div>
      </div>
      <img
        src={process.env.PUBLIC_URL + `/images/about/about-me.jpg`}
        alt={"About me"}
        className="object-cover max-h-80 md:max-h-full w-full "
      />
    </div>
  );
}
