import { ArrowRightIcon } from "@primer/octicons-react";

export const ExternalLink = ({ url, children }) => {
  //
  return (
    <a href={url}>
      {children} <ArrowRightIcon verticalAlign={"bottom"} className="mb-1" />
    </a>
  );
};
