import { projectsData } from "../data/projectsData";
import { ProjectCard } from "../components";

export function Home({ hideNavMenu }) {
  //
  return (
    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 xl:grid-cols-3">
      {projectsData.map((project, index) => (
        <ProjectCard key={index} project={project} hideNavMenu={hideNavMenu} />
      ))}
    </div>
  );
}
